@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family:'Noto Serif SC', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin:0; padding:0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  transition: all .2s linear;
}
.link-bg:hover {
  background-color: #D9A768;
}
.text-primary {
  color: #D9A768
}

.bg-primary {
  background-color: #D9A768
}

.border-primary {
  border-color: #D9A768
}

.arrow-link{
  color: #fff;
}
.arrow-link-2 {
  color: #000;
}
.arrow-link:hover, .arrow-link-2:hover {
  animation: slide1 1s ease-in-out infinite;
  color: #D9A768;
  
}

@keyframes slide1 {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}

.nav:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #D9A768;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.nav:hover:after { 
  width: 100%; 
  left: 0; 
}
.nav:hover {
  color: #D9A768;
}
.about_link:hover {
  background-color: #b88343;
}